@use '@angular/material' as mat;

$ZurichNg11-primary: mat.define-palette(mat.$grey-palette);
$ZurichNg11-accent: mat.define-palette(mat.$grey-palette);
$ZurichNg11-warn: mat.define-palette(mat.$red-palette);

$ZurichNg11-theme: mat.define-light-theme((
  color: (
    primary: $ZurichNg11-primary,
    accent: $ZurichNg11-accent,
    warn: $ZurichNg11-warn
  ),
  typography: mat.define-typography-config()
));
@include mat.core();
@include mat.core-theme($ZurichNg11-theme);
@include mat.all-component-themes($ZurichNg11-theme);

@import "~bootstrap-icons/font/bootstrap-icons.css";

.bi::before {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  right: 10px;
  font-size: large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//From vendor.css

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  margin: 0;
}

.mat-body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  margin: 0;
}

.mat-body-2 {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  margin: 0;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav>li {
  position: relative;
  display: block;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.nav>li>a:hover,
.nav>li>a:focus {
  text-decoration: none;
  background-color: #eee;
}

.nav>li.disabled>a {
  color: #777;
  text-decoration: none;
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: #eee;
  border-color: #337ab7;
  text-decoration: none;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav>li>a>img {
  max-width: none;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  // border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .container>.navbar-header,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container-fluid>.navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }

  @media (min-width: 768px) {

    .container>.navbar-header,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container-fluid>.navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .navbar-brand {
    float: left;
    height: 50px;
    padding: 5px 15px;
    font-size: 18px;
    line-height: 20px;
  }

  .navbar-brand:hover,
  .navbar-brand:focus {
    text-decoration: none;
  }

  .navbar-brand>img {
    display: block;
  }

  // @media (min-width: 768px) {

  //   .navbar>.container .navbar-brand,
  //   .navbar>.container-fluid .navbar-brand {
  //     margin-left: -15px;
  //   }
  // }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .navbar-toggle:focus {
    outline: 0;
  }

  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }

  .navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
  }

  @media (min-width: 768px) {
    .navbar-toggle {
      display: none;
    }
  }

  .navbar-nav {
    margin: 7.5px -15px;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .navbar-nav .open .dropdown-menu>li>a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
      padding: 5px 15px 5px 25px;
    }

    .navbar-nav .open .dropdown-menu>li>a {
      line-height: 20px;
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-nav .open .dropdown-menu>li>a:focus {
      background-image: none;
    }
  }

  @media (min-width: 768px) {
    .navbar-nav {
      float: left;
      margin: 0;
      width: 100%;
    }

    .navbar-nav>li {
      float: left;
    }

    .navbar-nav>li>a {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.title-center {
  text-align: center;
}

li.k-item {
  border: 1px solid #dddddd !important;
}

li.k-state-active {
  border-bottom-color: transparent !important;
}

a {
  text-decoration: none;
}

// turns off detail grid hierarchy column as a bug fix

.k-grid .k-hierarchy-cell a {
   width: 0;
}

.k-grid .k-header.k-hierarchy-cell {
   border: none;
}

.k-grid .k-hierarchy-cell .k-icon {
  display: none;
}

.k-grid .k-hierarchy-col {
  width: 1px;
}

.k-checkbox {
  border-width: 5px;
}

.k-dropdownlist {
  width: 250px;
}

.swagger-ui .info hgroup.main {
  display: none;
}

.swagger-ui .btn.authorize {
  display: none;
}

.swagger-ui .scheme-container{
  display: none;
}

.integration-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  height: 32px;
  line-height: 32px;
}

.search-icon-btn {
  line-height: 34px !important;
  font-weight: 500 !important;
}

.search-icon {
  font-size: 24px !important;
}

// .mat-mdc-dialog-container .mdc-dialog__content {
//   line-height: inherit !important;
//   font-size: inherit !important;
//   font-weight: inherit !important;
//   letter-spacing: inherit !important;
// }
